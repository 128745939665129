import {
  ButtonDS,
  CheckboxAtom,
  GlobalIcon,
  Typography,
} from "get-life-storybook-ts";
import { getMessage } from "../PaymentCardContent/PaymentCardContent.component";
import PDFViewer from "../PDF/PDFViewer";
import CheckoutPayController from "./CheckoutPay.controller";
import "./checkOutPay.scss";

const CheckoutPay = () => {
  const {
    activeButton,
    confirmed,
    handleChangeConfirmed,
    handleRequest,
    note,
    noteUrl,
    translate,
    userAcceptance,
    setUserAcceptance,
    userHasConditionsToAccept,
    domain
  } = CheckoutPayController();
  const messageWarning = getMessage({
    message: translate("informativeNote.warning"),
    replace: <b>{translate("informativeNote.buttonSignText")}</b>,
  });

  return (
    <div className="checkoutPay-bodyContainer">
      <div className="checkoutPay-noteContainer">
        <Typography
          text={translate("informativeNote.title")}
          variant="H2"
          weight="700"
          className="text-[#424242]"
        />
        <p className="checkoutPay--subtitle">
          <GlobalIcon
            iconName="InfoIcon"
            size="XXS"
            color="var(--theme-primary)"
            className="checkoutPay--infoIcon"
          />
          <Typography
            text={translate("informativeNote.subtitle")}
            variant="BodyMedium"
            weight="500"
          />
        </p>
        {note && <PDFViewer url={note} translate={translate} />}
      </div>
      <div className="checkoutPay-buttonContainer">
        {userHasConditionsToAccept ? (
          <div className="checkoutPay-checkboxes">
            <CheckboxAtom
              labelTitle={
                <span
                  dangerouslySetInnerHTML={{
                    __html: translate("informativeNote.privacyPolicies"),
                  }}
                />
              }
              isLegal={true}
              checked={userAcceptance.privacyPolicies}
              onValueChange={(value) =>
                setUserAcceptance({ ...userAcceptance, privacyPolicies: value })
              }
            />
            <CheckboxAtom
              labelTitle={
                <span
                  dangerouslySetInnerHTML={{
                    __html: translate("informativeNote.personalData"),
                  }}
                />
              }
              isLegal={true}
              checked={userAcceptance.personalData}
              onValueChange={(value) =>
                setUserAcceptance({ ...userAcceptance, personalData: value })
              }
            />
            <CheckboxAtom
              labelTitle={
                <span
                  dangerouslySetInnerHTML={{
                    __html: translate("informativeNote.conditions"),
                  }}
                />
              }
              isLegal={true}
              checked={userAcceptance.conditions}
              onValueChange={(value) =>
                setUserAcceptance({ ...userAcceptance, conditions: value })
              }
            />
          </div>
        ) : null}
        <div className="checkoutPay-innerButtonContainer">
          { domain !== "fr" && <ButtonDS
            label={translate("informativeNote.buttonSign")}
            disabled={!activeButton}
            onClick={handleRequest}
            className="w-full"
            rightIcon="ArrowRightIcon"
          />}
          {noteUrl !== null && (
            <ButtonDS
              href={noteUrl!}
              rel="noreferrer"
              target="_blank"
              leftIcon="FileDownload"
              buttonType="secondary"
              label={translate("informativeNote.downloadPdf")}
              className="w-full"
            />
          )}
        </div>
        <div className="checkoutPay-messages">
          <span>{messageWarning}</span>
          <p>{translate("informativeNote.extraPhrase")}</p>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPay;
